import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import * as API from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { Button, ModalHeader, Modal, ModalBody } from "@windmill/react-ui";
import { injected, walletconnect, walletlink } from "../connectors";
import { Spinner } from "../components/Spinner";
import ConnectModal from "../components/ConnectModal";
import CountDown from "../components/CountDown";
import { useGlobalContext } from "../context/GlobalContext";

import styles from "../styles/connectWallet.module.scss";

const ConnectorNames = {
  Injected: "Metamask",
  Network: "Network",
  WalletConnect: "WalletConnect",
  WalletLink: "WalletLink",
  Ledger: "Ledger",
  Trezor: "Trezor",
  Lattice: "Lattice",
  Frame: "Frame",
  Authereum: "Authereum",
  Fortmatic: "Fortmatic",
  Magic: "Magic",
  Portis: "Portis",
  Torus: "Torus",
};

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network. Connect to ETH Mainnet.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

export default function () {
  return <ConnectWallet />;
}

function ConnectWallet() {
  const context = useWeb3React();
  // console.log(context);
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const {
    isOnWhitelist,
    setIsOnWhitelist,
    setSalt,
    salt,
    saleStatusHooks,
    setSaleStatusHooks,
  } = useGlobalContext();

  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [signaturePending, setSignaturePending] = useState(false);
  const [mintError, setMintError] = useState("");
  const [whitelistModal, setWhitelistModal] = useState(false);
  const [mintNotOpen, setMintNotOpen] = useState(undefined);

  useEffect(() => {
    if (error) {
      setIsConnectModalOpen(false);
    }
  }, [error]);

  const getSaleStatus = () => {
    console.log("Sale Status in connect wallet");
    try {
      API.saleStatus().then((resp) => {
        console.log(resp);
        setSaleStatusHooks(resp);
      });
    } catch (e) {}
  };

  useEffect(() => {
    getSaleStatus();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (active && account) {
      setMintNotOpen(undefined);
      API.updateProviderAndSigner(library);
      if (saleStatusHooks.publicsale) {
        return navigate("/mint");
      } else if (saleStatusHooks.privatesale) {
        // check if address is whitelisted first
        // then proceed
        API.retrieveWalletIsOnWhitelist(account).then((resp) => {
          let not_whitelisted = !resp.whitelisted ? true : false;
          if (not_whitelisted) {
            setWhitelistModal(not_whitelisted);
          } else {
            return navigate("/mint");
          }

          setIsConnectModalOpen(false);
        });
      } else {
        setIsConnectModalOpen(false);
        setMintNotOpen("Sale has not started yet, check back later.");
      }
    }
  }, [context, saleStatusHooks]);

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  return (
    <>
      {isConnectModalOpen && (
        <ConnectModal
          key={isConnectModalOpen}
          setIsConnectModalOpen={setIsConnectModalOpen}
          setActivatingConnector={setActivatingConnector}
          setMintError={setMintError}
          signaturePending={signaturePending}
        />
      )}
      <div className={styles.container}>
        <div className={`${styles.mintText}`}>
          <div className={`${styles.textBox}`}>
            <p className={styles.textBold}>
              {!account ? "Your Wallet Is Not Connected" : ""}
            </p>
            <p
              className={`${styles.subText} text-xl mb-8 aos-init aos-animate`}
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              {mintNotOpen
                ? mintNotOpen
                : "To get your Drug Receipts NFT you’ll need to connect your Ethereum wallet."}
            </p>
            {!!error && (
              <div style={{ width: "100%" }}>
                <h4
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  className={`${styles.errorMessage} text-red-700`}
                >
                  {getErrorMessage(error)}
                </h4>
              </div>
            )}
            <div
              className="mx-auto sm:max-w-none sm:flex sm:justify-center aos-init aos-animate"
              data-aos="zoom-y-out"
              data-aos-delay="300"
            >
              {!account ? (
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.connectButton}
                    size="large"
                    onClick={() => {
                      setIsConnectModalOpen(true);
                    }}
                  >
                    Connect Wallet
                  </button>
                </div>
              ) : (
                <div>
                  <div style={{ marginTop: 25 }}>
                    <button
                      className={styles.connectButton}
                      onClick={() => {
                        setMintError("");
                        deactivate();
                      }}
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              )}
            </div>
            {mintError && (
              <div className={`${styles.errorMessage} mt-4 mb-3 text-red-700`}>
                {mintError}
              </div>
            )}
          </div>
          <Modal
            isOpen={whitelistModal}
            onClose={() => {
              setWhitelistModal(false);
            }}
          >
            <ModalBody>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="space-y-4 pt-4 pb-4"
              >
                <div className={styles.awaitingText} style={{ fontSize: 16 }}>
                  We're sorry, it looks like you're not on the whitelist.
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
}
