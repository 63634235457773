import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { LedgerConnector } from "@web3-react/ledger-connector";
import { TrezorConnector } from "@web3-react/trezor-connector";
import { LatticeConnector } from "@web3-react/lattice-connector";
import { FrameConnector } from "@web3-react/frame-connector";
import { AuthereumConnector } from "@web3-react/authereum-connector";
import { FortmaticConnector } from "@web3-react/fortmatic-connector";
import { MagicConnector } from "@web3-react/magic-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { TorusConnector } from "@web3-react/torus-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/4d62989aa1ba4ee29f4e277d91b344d7"
};

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1] },
  defaultChainId: 1,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: "Drugreceipts Mint",
  supportedChainIds: [1],
});

export const ledger = new LedgerConnector({
  chainId: 1,
  url: RPC_URLS[1],
  pollingInterval: POLLING_INTERVAL,
});

export const trezor = new TrezorConnector({
  chainId: 1,
  url: RPC_URLS[1],
  pollingInterval: POLLING_INTERVAL,
  manifestEmail: "dummy@abc.xyz",
  manifestAppUrl: "http://localhost:1234",
});

export const lattice = new LatticeConnector({
  chainId: 4,
  appName: "web3-react",
  url: RPC_URLS[4],
});

export const frame = new FrameConnector({ supportedChainIds: [1] });

export const authereum = new AuthereumConnector({ chainId: 1 });

export const fortmatic = new FortmaticConnector({ apiKey: "", chainId: 1 });

export const magic = new MagicConnector({
  apiKey: "",
  chainId: 4,
  email: "hello@example.org",
});

export const portis = new PortisConnector({ dAppId: "", networks: [1] });

export const torus = new TorusConnector({ chainId: 1 });
