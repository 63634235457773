import React, { useState, useEffect } from "react";
import { injected, walletconnect, walletlink } from "../connectors";
import { Spinner } from "../components/Spinner";
import styles from "../styles/connectModal.module.scss";

import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";

const ConnectModal = ({ setIsConnectModalOpen, signaturePending }) => {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const [activatingConnector, setActivatingConnector] = useState();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  return (
    <div className={styles.wholePageDark}>
      <div className={styles.container}>
        <div
          onClick={() => setIsConnectModalOpen(false)}
          className={styles.closeButtonContainer}
        >
          <img className={styles.closeButton} src="/X.svg" alt="close icon" />
        </div>

        <div className={styles.sectionTop}>
          <h3>Connect Wallet</h3>
          <p>Your Drug Receipts NFT is Waiting!</p>

          {signaturePending && (
            <p className={styles.awaitingText}>
              Awaiting signature from wallet
            </p>
          )}
        </div>

        <div className={styles.imgContainer}>
          <img src="/modal_char.png" alt="" />
        </div>

        <div className={styles.sectionBottom}>
          <button
            disabled={signaturePending}
            onClick={() => {
              setActivatingConnector("Metamask");
              activate(injected);
            }}
            className={styles.connectButton}
          >
            {signaturePending &&
            activatingConnector &&
            activatingConnector === "Metamask" ? (
              <Spinner margin="auto" color="black" />
            ) : (
              "MetaMask"
            )}
          </button>
          <button
            disabled={signaturePending}
            onClick={() => {
              setActivatingConnector("WalletConnect");
              activate(walletconnect);
            }}
            className={styles.connectButton}
          >
            {signaturePending &&
            activatingConnector &&
            activatingConnector === "WalletConnect" ? (
              <Spinner margin="auto" color="black" />
            ) : (
              "Wallet Connect"
            )}
          </button>
          <button
            disabled={signaturePending}
            onClick={() => {
              setActivatingConnector("WalletLink");
              activate(walletlink);
            }}
            className={styles.connectButton}
          >
            {signaturePending &&
            activatingConnector &&
            activatingConnector === "WalletLink" ? (
              <Spinner margin="auto" color="black" />
            ) : (
              "Coinbase Wallet"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
